<template>
    <div class="settings">
        <div class="settings_top">
            <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
        </div>

        <div class="settings_box">
            <div class="settings_box_item" v-if="current[0] === '0'">
                <user-set></user-set>
            </div>











        </div>











    </div>

</template>
<script lang="ts" setup>

import userSet from './userSet.vue';

import { MenuProps } from 'ant-design-vue';
import { ref } from 'vue';
const activeKey = ref('1');


//初始tab选中0
const current = ref<string[]>(['0']);
//tab数据
const items = ref<MenuProps['items']>([
    {
        key: '0',
        label: '账号设置',
    }
]);



</script>

<style lang="less" scoped>
.settings {
    width: 100%;
    height: calc(100vh - 60px);
    overflow-x: auto;
    display: flex;
    flex-direction: column;

    .settings_top {
        background-color: #fff;
    }

    .settings_box {
        flex: 1;

        .settings_box_item {
            height: 100%;
        }
    }
}
</style>