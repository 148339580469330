<template>
    <div class="index">
        <a-layout style="height: 100vh;overflow: hidden;">
            <a-layout-sider v-model:collapsed="collapsed" collapsible theme="light">
                <div class="logo" />
                <img src="@/assets/logo.png" style="width: 100%;padding: 15px 20px;" alt="">
                <a-menu v-model:selectedKeys="state.selectedKeys" style="width: 100%;border:none;" mode="inline"
                    :open-keys="state.openKeys" :items="items" @openChange="onOpenChange" theme="light"
                    @click="handleClick"></a-menu>
            </a-layout-sider>
            <a-layout style="border-left: 1px solid #f0f0f0;">
                <a-layout-header style="padding: 0;height: 60px;">
                    <web_top />
                </a-layout-header>
                <a-layout-content class="app_content">
                    <a-spin class="loading" :spinning="store.state.spinning" tip="Loading...">
                        <router-view />
                    </a-spin>
                </a-layout-content>
                <!-- <a-layout-footer style="text-align: center">
                Ant Design ©2018 Created by Ant UED
            </a-layout-footer> -->
            </a-layout>
        </a-layout>

    </div>
</template>
<script lang="ts" setup>



import web_top from '@/components/web_top.vue';
import { useStore } from 'vuex';
const store = useStore();

import { ref, VueElement, h, reactive, watch, computed } from 'vue';

import { useRoute } from 'vue-router';


const route = useRoute();


//监听router 改变切换侧边tab导航栏
watch(() => route, (newRoute) => {
    state.selectedKeys = [newRoute.fullPath];
}, { deep: true })



const collapsed = ref<boolean>(false);
import { HomeOutlined, SettingFilled, SettingOutlined, TeamOutlined, ApartmentOutlined } from '@ant-design/icons-vue';



import router from '@/router';

let user = JSON.parse(store.state.user)

console.log(user);






//点击侧边导航栏跳转home子页面
function handleClick(info: any) {
    router.push({ path: info.key })
}


const items = reactive([
    {
        key: '/home',
        icon: () => h(HomeOutlined),
        title: '首页',
        label: '首页',
        disabled: false
    },
    {
        key: '/bankBusiness',
        icon: () => h(ApartmentOutlined),
        title: '转账工作台',
        label: '转账工作台',
        disabled: user.roule === 4 ? true : false
    },
    {
        key: '/rolemanagement',
        icon: () => h(TeamOutlined),
        title: '管理员设置',
        label: '管理员设置',
        disabled: user.roule === 0 ? false : true
    },
    {
        key: '/settings',
        icon: () => h(SettingOutlined),
        title: '设置',
        label: '设置',
        // disabled: user.roule === 0 ? false : true
    }

]);

const state = reactive({
    rootSubmenuKeys: ['/home'],
    openKeys: ['/home'],
    selectedKeys: [router.currentRoute.value.fullPath],
});






const onOpenChange = (openKeys: [string]) => {
    const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1);
    if (state.rootSubmenuKeys.indexOf(<string>latestOpenKey) === -1) {
        state.openKeys = openKeys;
    } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : [];
    }
};






</script>
<style lang="less">
* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

li,
ul,
ol {
    list-style: none;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    // padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}


.app_content {
    width: auto;
    // height: 838px;
    // margin: 6vh 2vw;
    // box-shadow: 0 0 20px #ccc;
    background: #fff;
    border-radius: 5px;
    box-sizing: border-box;
}
</style>