<template>
    <div class="home">
        <a-descriptions bordered size="small" :column='2'>
            <!-- <template #extra>
                <a-space direction="vertical" style="margin: 0 20px;">
                </a-space>
                <a-form layout="inline">
                    <a-form-item label="">
                        <a-range-picker v-model:value="value1" :show-time="{ format: 'HH:mm' }" :locale="locale"
                            format="YYYY-MM-DD HH:mm" @change="pickerChangeFun" />
                    </a-form-item>
                    <a-form-item label="">
                        <a-input v-model:value="packageNo" placeholder="请输入订单号" />
                    </a-form-item>
                    <a-form-item>
                        <a-select ref="select" v-model:value="status" style="width: 100px">
                            <a-select-option value="1">全部</a-select-option>
                            <a-select-option value="2">成功</a-select-option>
                            <a-select-option value="3">失败</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="finishFun">搜索</a-button>
                    </a-form-item>
                </a-form>
            </template> -->



            <a-descriptions-item label="账户名称">{{ user.payAccName }}</a-descriptions-item>
            <a-descriptions-item label="卡号">{{ user.payAccNo }}</a-descriptions-item>
            <a-descriptions-item label="余额">{{ balance }}</a-descriptions-item>
            <!-- <a-descriptions-item label="昨日日终余额">{{ lastDayBalance }}</a-descriptions-item> -->
            <a-descriptions-item label="当前可用余额">
                <!-- <a-statistic title="" :value="usableBalance" style="margin-right: 50px" /> -->
                {{ usableBalance }}
            </a-descriptions-item>
        </a-descriptions>

        <div class="home_box">
            <!-- <a-table class="components-table-demo-nested" :columns="columns" :data-source="list" :scroll="{ y: 400 }"
                :pagination="false">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'Controls'">
                        <a-button type="primary" @click="StatusQueryFun(record)">查询状态</a-button>
                    </template>
                </template>
            </a-table> -->

            <div class="home_box_left">
                <a-descriptions :column='1' bordered>
                    <a-descriptions-item label="用户名称">{{ ress.conpey_name }}</a-descriptions-item>
                    <a-descriptions-item label="用户账号">{{ ress.mobile }}</a-descriptions-item>
                    <a-descriptions-item label="上次登录时间">{{ ress.last_login_time }}</a-descriptions-item>
                    <a-descriptions-item label="登录次数">{{ ress.login_num }}</a-descriptions-item>
                </a-descriptions>
            </div>
            <div class="home_box_right" v-if="(user.roule === 1 || user.roule === 4) ? false : true">
                <a-card :title="`待办事项${res.daiban}`" style="width: 100%;height: 20%;">
                    <p style="color: red;">{{ res.tixing }}</p>
                </a-card>
                <a-card title="快速审批" style="width: 100%;height: 50%;margin-top: 20px;">
                    <!-- <a-empty /> -->
                    <a-table class="components-table-demo-nested" :columns="column" :data-source="lists" border
                        :scroll="{ y: 200 }" :pagination="false">

                        <template #bodyCell="{ column, record }">
                            <template v-if="column.dataIndex === 'amount'">
                                <span style="color:red;">{{ record.amount }}</span>
                            </template>
                            <template v-if="column.dataIndex === 'Controls'">
                                <a-space warp>
                                    <a-button type="primary" v-if="(user.roule === 3 || user.roule === 0)"
                                        :disabled="record.status === '1' ? false : true"
                                        @click="MakePaymentFun(record)">
                                        去审核
                                    </a-button>
                                </a-space>
                            </template>
                        </template>
                    </a-table>
                </a-card>
            </div>
            <div class="home_box_right" v-if="user.roule === 4"
                style="display: flex;flex-direction: column;align-items: flex-end;">
                <a-form layout="inline" style="margin: 10px 0;">
                    <a-form-item label="">
                        <a-range-picker v-model:value="value1" :show-time="{ format: 'HH:mm' }" :locale="locale"
                            format="YYYY-MM-DD HH:mm" @change="pickerChangeFun" />
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="finishFun">搜索</a-button>
                    </a-form-item>
                </a-form>
                <a-table :columns="columns" :data-source="list" :scroll="{ y: '540px' }" @change="paginationChange"
                    :pagination="{ total: total, current: current, pageSize: 10 }">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'Controls'">
                            <a-button style="width:100px;margin-bottom: 10px;" v-if="record.retNo !== ''" type="primary"
                                @click="ReceiptQueryFun(record)">下载回单</a-button>


                            <a-popconfirm title="是否确认操作?" ok-text="确认" cancel-text="取消" v-if="record.drcType !== '支出'"
                                @confirm="popconfirmConfirmFun(record)" @cancel="popconfirmCancelFun"
                                :disabled="record.status === '已处理'">
                                <a-button style="width:100px;" v-if="record.retNo !== ''" type="primary">{{
                                    record.status === "已处理" ? "已处理" : '确认打款' }}</a-button>
                            </a-popconfirm>
                        </template>
                    </template>
                </a-table>
            </div>
        </div>


        <!-- <div class="home_box">
            <a-table :columns="columns" :data-source="list" :scroll="{ y: '600px' }" @change="paginationChange"
                :pagination="{ total: total, current: current, pageSize: 10 }">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'Controls'">
                        <a-button v-if="record.retNo !== ''" type="primary"
                            @click="ReceiptQueryFun(record)">下载回单</a-button>
                    </template>
                </template>
            </a-table>
        </div>

        <a-modal v-model:open="open" width="800px" title="查询状态" @ok="handleOk" :footer="null">
            <a-table :columns="columnss" :scroll="{ y: 300 }" :pagination='false' :data-source="dataSource" />
        </a-modal> -->

        <a-modal v-model:open="dakuanOpen" title="确认转账吗？确认后金额立即转出" okText="确认" cancelText="关闭" @ok="zhuanzhangFun">
            <a-space>
                <a-select ref="select" v-model:value="auditType" style="width: 120px">
                    <a-select-option value="1">同意</a-select-option>
                    <a-select-option value="0">拒绝</a-select-option>
                </a-select>
            </a-space>


        </a-modal>


    </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { api_cw_get_finance_list, api_cw_balance_inquiry, api_cw_pay_check, api_cw_hd_check, api_cw_get_balance_list, api_cw_dk_cl, api_cw_jujue_dk, api_cw_single_pay } from '@/api/home';
import { api_cw_get_notice, api_cw_get_user_info } from '@/api/home'
import { message, type TableColumnsType } from 'ant-design-vue';

import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

import type { Dayjs } from 'dayjs';

import router from "@/router";

let date = ref<any>([]);


type RangeValue = [Dayjs, Dayjs];
const value1 = ref<RangeValue>();
let current = ref<number>(1)
let user: any = localStorage.getItem('user') || sessionStorage.getItem('user');
user = JSON.parse(user);

let status = ref<string>('1')


watch(value1, (newValue, oldValue) => {
    if (newValue === null) {
        list.value = [];
        current.value = 1;
        total.value = 1;
    }
})

let packageNo = ref('');

let circulation: any = null;

onMounted(() => {
    // console.log('组件加载');
    api_cw_balance_inquiryFun();
    date.value[0] = dateFun(new Date().getTime() - 86400000);
    date.value[1] = dateFun(new Date().getTime());





    if (user.roule === 4) finishFun('');
    api_cw_get_noticeFun();


    // circulation = setInterval(() => {
    //     api_cw_get_noticeFun();
    // }, 5000)

});


onUnmounted(() => {
    console.log('退出');

    // clearInterval(circulation)
})






let dakuanOpen = ref(false);
let shenheOpen = ref(false);
let auditType = ref<string>('0');
let TemporaryStorage = reactive<any>({});


const MakePaymentFun = (e: any) => {
    console.log(e);

    if (e.status === '0') {
        shenheOpen.value = !shenheOpen.value;
    } else {
        dakuanOpen.value = !dakuanOpen.value;
    }
    TemporaryStorage = e.item;
};






let res = ref<any>({});
let ress = ref<any>({});

const api_cw_get_noticeFun = async () => {
    lists.value = [];
    let data: any = await api_cw_get_notice(user.openid);
    let datas = await api_cw_get_user_info(user.openid);

    res.value = data;
    ress.value = datas;

    if (data.order.length !== 0) {
        data.order.forEach((item: any) => {
            let obj = {
                recAccNo: item.recAccNo,
                recAccName: item.recAccName,
                isSameBank: item.isSameBank === '1' ? '否' : '是',
                amount: item.amount,
                memo: item.memo,
                recBankNo: item.recBankNo ? item.recBankNo : '-',
                recBankName: item.recBankName ? item.recBankName : '-',
                status_name: item.status_name,
                status: item.status,
                time: item.time,
                item
            }
            lists.value.push(obj)
        })
    }

    console.log(data);

    console.log(data, datas);
}
const handleChange = (e: any) => {
    console.log(e);
}
const focus = (e: any) => {
    console.log(e);
}
const paginationChange = (e: any) => {

    current.value = e.current;
    console.log(e, '--------------------------------------------------');

    finishFun('');
}



let usableBalance = ref(0);
let balance = ref(0);
let lastDayBalance = ref(0);

let zhuanzhangFun = () => {
    if (auditType.value === '0') {
        api_cw_jujue_dkFun()
    } else {
        api_cw_single_payFun()
    }
}

//同意打款
const api_cw_single_payFun = async () => {
    let up = {
        payAccName: user.payAccName,
        payAccNo: user.payAccNo,
        recAccName: TemporaryStorage.recAccName,
        recAccNo: TemporaryStorage.recAccNo,
        amount: TemporaryStorage.amount,
        isSameBank: TemporaryStorage.isSameBank,
        recBankNo: TemporaryStorage.recBankNo,
        recBankName: TemporaryStorage.recBankName,
        openid: user.openid,
        memo: TemporaryStorage.memo,
        id: TemporaryStorage.id
    }
    let data: any = await api_cw_single_pay(up);
    console.log(data);
    if (data.error === 0) {
        api_cw_get_noticeFun();
    }
    dakuanOpen.value = !dakuanOpen.value;
    auditType.value = '0'
};

//取消或拒绝
let api_cw_jujue_dkFun = async () => {
    let up = {
        openid: user.openid,
        id: TemporaryStorage.id
    }
    let data: any = await api_cw_jujue_dk(up);
    if (data.error === 0) {
        // api_cw_get_zd_listFun();
        api_cw_get_noticeFun();
    }
    dakuanOpen.value = !dakuanOpen.value;
    auditType.value = '0'

}

const open = ref<boolean>(false);


const showModal = () => {
    open.value = true;
};

const handleOk = (e: MouseEvent) => {
    console.log(e);
    open.value = false;
};





const popconfirmConfirmFun = async (res: any) => {
    console.log(res.transSeqNb);
    let up = {
        openid: user.openid,
        transSeqNb: res.transSeqNb
    }
    let data: any = await api_cw_dk_cl(up);
    if (data.error === 0) {
        finishFun('');
    }

}
const popconfirmCancelFun = async () => {
    message.error('已取消')
}







const api_cw_balance_inquiryFun = async () => {
    let data: any = await api_cw_balance_inquiry(user.payAccNo, user.openid);
    usableBalance.value = data.usableBalance
    balance.value = data.balance
    lastDayBalance.value = data.lastDayBalance
    console.log(data);


}

// const columns: TableColumnsType = [
//     { title: '转账账号', width: 100, dataIndex: 'recAccNo' },
//     { title: '开户名', width: 100, dataIndex: 'recAccName' },
//     { title: '银行名称', width: 100, dataIndex: 'recBankName' },
//     { title: '转账金额', width: 100, dataIndex: 'amount' },
//     { title: '订单号', width: 100, dataIndex: 'packageNo' },
//     { title: '操作时间', width: 100, dataIndex: 'time' },
//     { title: '状态', width: 100, dataIndex: 'message' },
//     { title: '操作', width: 100, dataIndex: 'Controls' }
// ];


const ReceiptQueryFun = async (item: any) => {
    console.log(item);
    let up = {
        openid: user.openid,
        // accNo: item.recAccNo,
        accNo: user.payAccNo,
        retNo: item.retNo
    }
    let data: any = await api_cw_hd_check(up);
    if (data.error === 0) {
        base64ToPDF('data:application/pdf;base64,' + data.body.billFileContent, 'example.pdf')
    }
}


const column: TableColumnsType = [
    // { title: '收款人账号', dataIndex: 'recAccNo' },
    { title: '收款户名', dataIndex: 'recAccName' },
    // { title: '是否跨行', dataIndex: 'isSameBank' },
    { title: '打款金额', dataIndex: 'amount' },
    // { title: '附言', dataIndex: 'memo' },
    // { title: '行号', dataIndex: 'recBankNo' },
    { title: '银行名称', dataIndex: 'recBankName' },
    { title: '状态', dataIndex: 'status_name' },
    { title: '操作时间', dataIndex: 'time' },
    {
        title: '操作', dataIndex: 'Controls',
    },
];

const columns = [
    {
        title: '交易金额',
        dataIndex: 'amount',
        // width: '15%',
    },
    {
        title: '状态',
        dataIndex: 'drcType',
        width: '100px'
    },
    {
        title: '对方账户名称',
        dataIndex: 'recAccName',
        // width: '15%',
    },
    {
        title: '对方账号',
        dataIndex: 'recAccNo',
        // width: '15%',
    },
    {
        title: '交易流水号',
        dataIndex: 'transSeqNb',
        width: '15%',
    },
    {
        title: '交易日期',
        dataIndex: 'tranDt',
        width: '140px',
    },
    {
        title: '附言',
        dataIndex: 'memo'
    },
    {
        title: '操作',
        dataIndex: 'Controls',
        // width: '15%',
    }
];



const base64ToPDF = (base64String: string, pdfName: string) => {
    let binaryData = atob(base64String.split(',')[1]);
    let mimeString = base64String.split(',')[0].split(':')[1];
    let arrayBuffer = new ArrayBuffer(binaryData.length);
    let uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
        uintArray[i] = binaryData.charCodeAt(i);
    }
    let blob = new Blob([uintArray], { type: mimeString });
    let url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = pdfName || 'downloadedPDF.pdf';
    // 触发下载
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}








const columnss: TableColumnsType = [
    { title: '转账账号', width: "30%", dataIndex: 'recAccNo' },
    { title: '转账金额', width: "15%", dataIndex: 'amount' },
    { title: '转账状态', width: "30%", dataIndex: 'desc' },
]

let list: any = ref([]);
let lists: any = ref([]);
let dataSource: any = ref([]);

let total: any = ref(1);

const TheInverseDateFun = (str: string): string => {
    let year = str.slice(0, 4) + '年';
    let month = str.slice(4, 6) + '月';
    let day = str.slice(6, 8) + '日';
    let hour = str.slice(8, 10) + '时';
    let minute = str.slice(10, 12) + '分';
    let second = str.slice(12, 14) + '秒';
    return year + month + day + hour + minute + second
}


const finishFun = async (value: any) => {

    list.value = [];
    let up = {
        accNo: user.payAccNo,  //查询银行账号
        pageNo: current.value, //分页 （从1开始）
        beginDate: date.value[0],  //开始时间
        endDate: date.value[1], //结束时间
        openid: user.openid
    }
    let data: any = await api_cw_get_balance_list(up);
    console.log(data);
    if (data) {
        // return
        if (data.header.code === "000000") {
            total.value = data.body.totalNum;
            data.body.details.forEach((item: any) => {
                let obj = {
                    amount: item.amount / 100,
                    recAccNo: item.recAccNo,
                    recAccName: item.recAccName,
                    transSeqNb: item.transSeqNb,
                    tranDt: TheInverseDateFun(item.tranTime),
                    retNo: item.retNo,
                    drcType: item.drcType === 'D' ? '支出' : '收入',
                    memo: item.memo,
                    status: item.status
                }
                list.value.push(obj)
            })
            // for (let index = 0; index < 40; index++) {
            //     const element = data.body.details[0];
            //     // console.log(element);
            //     list.value.push(element)

            // }
        } else {
            if (data.header.msg) message.error(data.header.msg);
        }
    }
}


let pickerChangeFun = (e: any) => {
    console.log(e);

    if (e === null) {
        date.value[0] = dateFun(new Date().getTime() - 86400000);
        date.value[1] = dateFun(new Date().getTime());
        return
    }
    if (date.value.length === 2) {
        date.value = []
    }
    for (let index = 0; index < e.length; index++) {
        const element = e[index].$d;
        console.log(element);
        let str = dateFun(Date.parse(element))
        date.value.push(str);
    }
}

const StatusQueryFun = async (item: any) => {
    console.log(item);
    dataSource.value = [];

    let up = {
        openid: user.openid,
        queryPacNo: item.packageNo
    }
    console.log(up);

    let data: any = await api_cw_pay_check(up);
    if (data.header.code === '000000') {
        if (data.body.details.length !== 0) {
            open.value = true;
            data.body.details.forEach((item: any) => {

                let obj = {
                    recAccNo: item.recAccNo,
                    amount: item.amount / 100,
                    desc: item.desc
                }
                dataSource.value.push(obj);
            });
            console.log(dataSource.value);
        }
    }
}

const dateFun = (timestamp: number): string => {

    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`
}

</script>

<style lang="less" scoped>
.home {
    width: 100%;
    height: calc(100vh - 60px);
    // height: 100%;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .home_box {
        width: 100%;
        flex: 1;
        margin-top: 20px;
        display: flex;

        .home_box_left {
            width: 500px;
        }

        .home_box_right {
            flex: 1;
            height: 100%;
            padding: 0 20px;
        }
    }
}
</style>