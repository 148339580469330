<template>
    <div class="userSet">
        <a-form layout="vertical" :model="FormData" @finish="api_cw_set_infoFun">
            <a-form-item label="账号">
                <a-input v-model:value="FormData.mobile" placeholder="请输入账号" />
            </a-form-item>
            <a-form-item label="旧密码">
                <a-input v-model:value="FormData.old_pwd" placeholder="请输入旧密码" />
            </a-form-item>
            <a-form-item label="新密码">
                <a-input v-model:value="FormData.pwd" placeholder="请输入新密码" />
            </a-form-item>
            <a-form-item>
                <a-button type="primary" html-type="submit">保存</a-button>
            </a-form-item>
        </a-form>
    </div>
</template>
<script lang="ts" setup>
import { api_cw_set_info } from "@/api/home";
import router from "@/router";
import store from "@/store";
import { ref, reactive } from "vue";
const user = JSON.parse(store.state.user);

let FormData = reactive({
    mobile: '',
    old_pwd: '',
    pwd: ''
})

const logOutFun = () => {
    localStorage.clear();
    sessionStorage.clear();
    router.push('/')
}


const api_cw_set_infoFun = async () => {
    let up = {
        openid: user.openid,
        mobile: FormData.mobile,
        old_pwd: FormData.old_pwd,
        pwd: FormData.pwd
    }

    console.log(up);

    let data: any = await api_cw_set_info(up);

    if (data.error === 0) {
        logOutFun();
    }
    console.log(data);

}
</script>
<style lang="less" scoped>
.userSet {
    width: 100%;
    height: 100%;
    // background-color: antiquewhite;
    padding: 20px;
}
</style>