import ajax from './request';



interface API_CW_LOGIN {
    mobile: string;
    pwd: string;
    verifycode: string;
}
// 财务后台登录接口 api.cw.login
export function api_cw_login(data: API_CW_LOGIN) {
    return ajax('api.cw.login', 'POST', data, false)
}

// 财务软件登录短信发送  api.cw.verifycode

export function api_cw_verifycode(mobile: string) {
    return ajax('api.cw.verifycode', 'POST', { mobile })
}