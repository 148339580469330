<template>
    <div class="login" @wheel="handleWheel">
        <vue-particles id="tsparticles" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="{
        background: {
            color: {
                value: '#001529'
            }
        },
        fpsLimit: 180,
        interactivity: {
            events: {
                onClick: {
                    enable: false,
                    mode: 'push'
                },
                onHover: {
                    enable: true,
                    mode: 'repulse'
                },
                resize: true
            },
            modes: {
                bubble: {
                    distance: 200,
                    duration: 2,
                    opacity: 0.8,
                    size: 40
                },
                push: {
                    quantity: 0
                },
                repulse: {
                    distance: 200,
                    duration: 0.4
                }
            }
        },
        particles: {
            color: {
                value: '#ffffff'
            },
            links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
            },
            collisions: {
                enable: false
            },
            move: {
                direction: 'none',
                enable: true,
                outModes: {
                    default: 'bounce'
                },
                random: false,
                speed: 6,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    area: 800
                },
                value: 80
            },
            opacity: {
                value: 1
            },
            shape: {
                type: 'circle'
            },
            size: {
                value: { min: 1, max: 4 },
            }
        },
        detectRetina: true
    }" />

        <div class="login_box">
            <img src="@/assets/logo.png" alt="">
            <h1>数农在线财务管理系统</h1>
            <a-form :model="formState" name="normal_login" class="login-form" @finish="api_cw_loginFun"
                :label-col="labelCol" @finishFailed="onFinishFailed">
                <a-form-item label="账号" name="username" :rules="[{ required: true, message: '请输入您的用户名' }]">
                    <a-input v-model:value="formState.username" placeholder="请输入您的用户名">
                        <!-- <template #prefix>
                            <UserOutlined class="site-form-item-icon" />
                        </template> -->
                    </a-input>
                </a-form-item>

                <a-form-item label="密码" name="password" :rules="[{ required: true, message: '请输入您的密码' }]">
                    <a-input-password v-model:value="formState.password" placeholder='请输入您的密码'>
                    </a-input-password>
                </a-form-item>

                <a-form-item label="校验码" name="entifyCode" :rules="[{ required: true, message: '请输入您的校验码' }]">
                    <a-input v-model:value="formState.entifyCode" :maxlength="4" placeholder='请输入您的校验码'>
                        <template #suffix>
                            <div class="code" @click="refreshCode">
                                <SIdentify :identifyCode="identifyCode"></SIdentify>
                            </div>
                        </template>
                    </a-input>
                </a-form-item>


                <a-form-item label="验证码" name="code" :rules="[{ required: true, message: '请输入您的验证码' }]">
                    <a-input-search v-model:value="formState.code" placeholder="请输入您的验证码" :enter-button="CodeButton"
                        @search="onGetCode" />
                </a-form-item>







                <!-- <a-form-item>
                    <a-form-item name="remember" no-style>
                        <a-checkbox v-model:checked="formState.remember">记住我</a-checkbox>
                    </a-form-item>
                </a-form-item> -->

                <a-form-item>
                    <a-button type="primary" html-type="submit" class="login-form-button" style="padding: 0 20px;">
                        登录
                    </a-button>
                </a-form-item>
            </a-form>
        </div>




    </div>
</template>
<script lang="ts" setup>
import SIdentify from '@/components/Sidentify.vue';


let identifyCode = ref('') //图形验证码
let identifyCodes = ref('1234567890') //验证码出现的数字和字母

let labelCol = { style: { width: '70px' } };

const makeCode = (o: any, l: any) => {
    for (let i = 0; i < l; i++) {
        identifyCode.value += getRandomNumber(0, 9);
    }
}


function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const refreshCode = () => {
    identifyCode.value = ''
    makeCode(identifyCodes.value, 4);
}



const randomNum = (min: number, max: number) => {
    max = max + 1
    return Math.floor(Math.random() * (max - min) + min)
}

import { api_cw_login, api_cw_verifycode } from '@/api/login';



const api_cw_verifycodeFun = async (mobile: string) => {
    let data = await api_cw_verifycode(mobile);
    console.log(data);

}

import router from "@/router";
import { loadSlim } from "tsparticles-slim";

import { LockOutlined, UserOutlined } from '@ant-design/icons-vue';


let CodeButton = ref<string>('获取验证码')

let timelater = ref<number>(60)


const onGetCode = () => {
    if (formState.username === '数农财务管理') {
        formState.username = '17065077773';
    }
    if (identifyCode.value === formState.entifyCode) {
        api_cw_verifycodeFun(formState.username);
        let setTime = setInterval(() => {
            timelater.value--;
            CodeButton.value = timelater.value + '';
            if (timelater.value === 0) {
                timelater.value = 60;
                CodeButton.value = '重新获取';
                clearInterval(setTime);
            }
        }, 1000);
    } else {
        message.error('校验码有误')
    }
}




const api_cw_loginFun = async () => {

    if (formState.username === '数农财务管理') {
        formState.username = '17065077773';
    }

    if (identifyCode.value !== formState.entifyCode) {
        message.error('校验码有误')
        return
    }


    let up: any = {
        pwd: formState.password,
        mobile: formState.username,
        verifycode: formState.code,
    };
    let data: any = await api_cw_login(up);
    if (data.error === 0) {
        data.password = formState.password;
        data.username = formState.username;
        if (formState.remember) {
            localStorage.setItem('user', JSON.stringify(data));
        } else {
            localStorage.clear();
            sessionStorage.setItem('user', JSON.stringify(data));
        }
        setTimeout(() => {
            location.reload();
        }, 1)
        router.push({ path: '/home' });
    }

}

const handleWheel = (event: { ctrlKey: any; deltaY: number; preventDefault: () => void; }) => {
    // 检查是否按下了Ctrl键
    if (event.ctrlKey && event.deltaY !== 0) {
        event.preventDefault(); // 阻止默认的滚轮行为
    }
};


const particlesInit = async (engine: any) => {
    await loadSlim(engine);
};

const particlesLoaded = async (container: any) => {
    console.log(container);
};





import { reactive, onMounted, ref } from 'vue';
import { message } from "ant-design-vue";
import locale from 'ant-design-vue/es/locale-provider';

interface FormState {
    username: string;
    password: string;
    remember: boolean;
    code: string;
    entifyCode: string;
}

const formState = reactive<FormState>({
    username: '',
    password: '',
    remember: false,
    code: '',
    entifyCode: ''
});

onMounted(() => {
    // console.log('组件已挂载');
    identifyCode.value = ''
    makeCode(identifyCodes.value, 4)
    let user: any = localStorage.getItem('user') || '';
    if (user) user = JSON.parse(user);
    formState.password = user.password;
    formState.username = user.username;
    if (user.password && user.username !== '') {
        formState.remember = true;
    }
})


const onFinish = (values: any) => {
    if (formState.password === 'jin159684' && formState.username === 'admin') {
        if (formState.remember) {
            let str: any = {
                name: '海南交互农产品电子商务有限公司（测试账户）',
                AccountNumber: '1013853200000122',
                password: formState.password,
                username: formState.username
            }
            localStorage.setItem('user', JSON.stringify(str));
        } else {
            localStorage.clear();
            let str: any = {
                name: '海南交互农产品电子商务有限公司（测试账户）',
                AccountNumber: '1013853200000122',
                password: formState.password,
                username: formState.username
            }
            sessionStorage.setItem('user', JSON.stringify(str));
        }
        message.success('登录成功');
        router.push({ path: '/home' });
    } else {
        message.error('请确认账号密码');
        return
    }
};

const onFinishFailed = (errorInfo: any) => {
    message.info('输入框必填不得为空');

};


</script>
<style lang="less" scoped>
.login {
    width: 100vw;
    height: 100vh;

    .login_box {
        width: 450px;
        height: 700px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        box-shadow: 0 0 20px #ccc;
        background: #fff;
        // border-radius: ;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        justify-content: space-around;
        // padding-bottom: 100px;

        .login-form {
            width: 100%;
        }

        img {
            width: 150px;
        }

        h1 {
            font-size: 30px;
        }
    }

    .login-form-button {
        width: 100%;
        height: 40px;
    }
}
</style>